<template>
<v-menu
  attach
  open-on-hover
  close-on-click
  bottom
  tile
  eager
  transition="scale-transition"
  offset-y
  origin="top center"
  nudge-left="75"
  :activator="activator"
  content-class="overflow-hidden transform-center">
  <v-img src="assets/popin-top.svg" max-width="23px" max-height="12px" class="upper-arrow-position auto-margin" />
  <v-card flat color="azul2" min-width="350px" max-width="350px" class="pt-3">
    <v-card-title class="justify-center">{{nombre}}</v-card-title>
    <v-card-subtitle>{{rol}}</v-card-subtitle>
    <v-card-text>{{descripcion}}
      <a 
        target="_blank"
        v-for="(item, i) in socials" :key="`tooltip${nombre}-${i}`"
        class="d-block"
        :href="item.url">
        <img style="width: 48px" :src="`assets/ic-rrss-${item.type}-min.svg`" />
      </a>
      </v-card-text>
  </v-card>
</v-menu>
</template>

<script>
export default {
  name: 'TooltipContent',
  props: {
    activator: {
      default: undefined,
      required: true
    },
    nombre: {
      type: String,
      default: '',
      required: true
    },
    rol: {
      type: String,
      default: ''
    },
    descripcion: {
      type: String,
      default: ''
    },
    socials: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      
    }
  },
  methods: {
    gotoToSocial(item) {
      alert(item.type)
    }
  }
}
</script>

<style>
.upper-arrow-position {
  margin-bottom: -2px;
}
.transform-center {
  transform-origin: center top !important;
}
</style>